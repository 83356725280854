<template>
  <!--名单导入-->
  <div class="importRegistration">
    <el-dialog
      :visible="display"
      :before-close="cancel"
      lock-scroll
      :close-on-click-modal="showList"
      :close-on-press-escape="showList"
      size="large"
      :show-close="showList"
      :destroy-on-close="true"
    >
      <div slot="title" class="title">批量上传</div>
      <div class="smalTitle">仅支持excel格式文件</div>
      <el-steps :space="100" direction="vertical">
        <el-step title="下载机构模板"></el-step>
        <el-step title="在模板里填写相关信息"></el-step>
        <el-step title="上传表格"></el-step>
      </el-steps>
      <div class="uploadsing absolute">
        <el-button @click="downloadIng">下载ZIP</el-button>
      </div>
      <div style="margin-left:10px;">
        <single-Upload
          v-if="!isPark"
          :uploadType="['.xls', '.xlsx', '.xlsm', '.xlt', '.xltx', '.xltm']"
          :uploadAddress="modelAddress"
          :uploadDate="modelDate"
          :planList="planList"
          @changeUploadData="changeUploadData"
          @cancelUpload="cancelUpload"
          :btnType="info"
          @unSupport="unSupport"
          @success="planuploadsuccess"
          @error="cancelUpload"
        >若无模板，上传模板</single-Upload>
        <single-Upload
          :uploadType="['.xls', '.xlsx', '.xlsm', '.xlt', '.xltx', '.xltm']"
          :uploadAddress="isPark?uploadParkAddress:uploadAddress"
          :uploadDate="isPark?uploadParkDate:uploadDate"
          :planList="planList"
          @changeUploadData="changeUploadData"
          @cancelUpload="cancelUpload"
          :btnType="info"
          @unSupport="unSupport"
          @success="planuploadsuccess"
          @error="cancelUpload"
        >填写表格，批量添加</single-Upload>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="large">取消</el-button>
        <el-button type="primary" @click="cancel" size="large" :disabled="!submitButton">完成</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
// import { mapState } from 'vuex';
import singleUpload from "@/components/Upload/singleUpload.vue";
import { warning } from "@/utils/notification";
export default {
  props: { display: {}, isPark: {}},
  data() {
    return {
      loading: false,
      showList: false,
      uploadAddress: this.URL.superUrl.excelOrgans, // 批量上传地址
      modelAddress: this.URL.superUrl.uploadExcel, //模板上传信息
      uploadDate: { user_id: sessionStorage.user_id }, //批量上传地址
      modelDate: { user_id: sessionStorage.user_id }, //模板上传信息
      uploadParkAddress: this.URL.adminUrl.uploadParkAddress, // 地址批量上传地址
      uploadParkDate: { organ_id: sessionStorage.organizationId }, //地址模板上传信息
      planList: [], // 计划书上传展示列表
      uploadShow: [], // 计划书上传列表,需要存数据啦
      submitButton: true, // 默认true
      info: "info"
    };
  },
  components: {
    singleUpload
  },
  // computed: {
  //     ...mapState({
  //         importRegistrationDisplay: state =>
  //             state.myActivity.importRegistrationDisplay,
  //         activityId: state => state.myActivity.activityData.activityId
  //     })
  // },
  methods: {
    cancel() {
      if (!this.submitButton) {
        warning("请等待上传完毕再取消或者取消上传");
      } else {
        this.$emit("cancel");
      }
    },
    next() {
      if (!this.submitButton) {
        warning("请等待上传完毕再提交");
      } else if (this.uploadShow.length === 0) {
        warning("请上传表格");
      } else {
        alert("过");
      }
    },
    downloadIng() {
      let url = ''
      if(this.isPark){
          this.$http.get(this.URL.adminUrl.parkTemplate).then((res) => {
            console.log(res.data.data)
          })
        // url =
        //   this.URL.adminUrl.parkTemplate;
        //   window.open(url);
      }else{
        url =
          this.URL.superUrl.downloadExcel + "?token=" + sessionStorage.superToken;
          window.open(url);
      }
    },
    unSupport() {
      this.submitButton = true;
    },

    // 上传前更改上传数据
    changeUploadData() {
      this.submitButton = false;
      // this.uploadDate.activity_id = this.activityId;
    },

    // 取消上传
    cancelUpload() {
      this.submitButton = true;
      this.uploadShow = [];
    },

    // 上传成功
    planuploadsuccess() {
      this.submitButton = true;
      // this.uploadShow.push({ inquiry_id: res.inquiry_id });
    }
  }
  //确定后批量上传
  // watch: {
  //     importRegistrationDisplay: function(e) {
  //         if (e) {
  //         } else {
  //             this.$store.dispatch('AllMemberControl', false);
  //             this.planList = [];
  //         }
  //     }
  // }
};
</script>

<style lang="less">
.importRegistration {
  .el-dialog {
    width: 488px;
  }
  .el-dialog__body {
    position: relative;
    padding-top: 14px;
  }
  .title {
    font-size: 20px;
    color: #1f2d3d;
    font-weight: bolder;
  }
  .smalTitle {
    font-size: 14px;
    color: #8492a6;
    margin-bottom: 48px;
  }
  .uploadsing {
    position: absolute;
    top: 100px;
    left: 53px;
  }
  .el-step__line.is-vertical {
    top: 20px;
    left: 10px;
    background: #eff2f7;
    width: 1px;
  }
  .el-step__head.is-text.is-wait {
    background: #e6e0ed;
    width: 20px;
    height: 20px;
    border: none;
  }
  .el-step__icon {
    line-height: 20px;
    font-size: 12px;
    color: #475669;
  }
  .el-step__title.is-wait {
    font-size: 14px;
    color: #475669 !important;
    line-height: 20px;
  }
}
</style>
