<template>
  <div id="organization">
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="17">
        <!-- <el-button type="primary" style="margin-bottom:10px" size="small">返回</el-button> -->
        <div class="search-input" style="margin-bottom: 20px">
          <el-input
            style="display: inline-block;width: 30%;"
            placeholder="请输入搜索内容"
            prefix-icon="el-icon-search"
            clearable
            v-model="inputValue"
          ></el-input>
          <el-button
            @click="searchOrgan"
            type="primary"
            style="margin-left: 10px"
            :loading="load"
          >{{load?'搜索中...': '搜索'}}</el-button>
        </div>
      </el-col>
      <el-col :span="4">
        <div style="text-align:right">
          <el-button
            size="small"
            type="primary"
            @click="()=>{this.$router.push('/organization/add')}"
          >添加机构</el-button>
          <el-button size="small" type="primary" @click="addFiles">批量添加</el-button>
        </div>
      </el-col>
    </el-row>
    <el-table :data="organizationData">
      <el-table-column align="center" label="图片">
        <template slot-scope="scope">
          <img v-if="scope.row.image" :src="scope.row.image" alt="机构管理图片" width="100" height="60" />
          <img v-else src="@/assets/images/暂无图片.png" width="100" height="60" />
        </template>
      </el-table-column>
      <el-table-column prop="map" align="center" label="地理位置">
        <template slot-scope="scope">
          <span>{{scope.row.province?scope.row.province+'/':''}}{{scope.row.city?scope.row.city+'/':''}}{{scope.row.district}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="机构名称"></el-table-column>
      <el-table-column prop="tel" align="center" label="联系电话"></el-table-column>
      <el-table-column prop="industry" align="center" label="行业领域">
        <template slot-scope="scope">
          <el-tag size="medium" v-for="(item,i) in scope.row.industry" :key="i">{{item}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="attribute" align="center" label="属性标签">
        <template slot-scope="scope">
          <el-tag size="medium" v-for="(item,i) in scope.row.attribute" :key="i">{{item}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="measure" align="center" label="载体面积"></el-table-column>
      <el-table-column prop="rentPrice" align="center" label="租金价格">
        <template slot-scope="scope">
          <span>{{scope.row.rent_from}}~{{scope.row.rent_to}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="service_type" align="center" label="服务类别">
        <template slot-scope="scope">
          <el-tag size="medium" v-for="(item,i) in scope.row.service_type" :key="i">{{item}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-row type="flex" style="justify-content: space-between">
            <el-select
              @change="stateChange($event,scope.row)"
              v-model="scope.row.state"
              placeholder="状态"
              size="small"
              style="width:60%"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-button @click="editClick(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="delClick(scope.row)" type="text" size="small" style="color:red">删除</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Page
      @currentPageChange="currentPageChange"
      :pageSize="pageData.per_page"
      :page="pageData.current_page"
      :total="pageData.total"
      style="text-align:center"
    ></Page>
    <!-- 批量添加文件 -->
    <importRegistration :display="display" @cancel="cancel" />
  </div>
</template>
<script>
import Page from "@/components/PageComponents";
import { success, error } from "@/utils/notification";
import importRegistration from "@/components/importRegistration";
//搜索节流函数
// const delay = (function() {
//   let timer = 0;
//   return function(callback, ms) {
//     clearTimeout(timer);
//     timer = setTimeout(callback, ms);
//   };
// })();
export default {
  name: "organization",
  components: { Page, importRegistration },
  data() {
    return {
      load: false,
      inputValue: "",
      organizationData: [],
      page: 1,
      pageData: {
        //分页数据
        current_page: 0,
        per_page: 0,
        total: 0
      },
      options: [{ id: 0, value: "审核中" }, { id: 1, value: "已审核" }],
      form: {
        type: 2, // 2是文件，1是文件夹
        pids: "", // 作为临时选择存放的条件，没得什么用
        pid: "",
        file_name: "", // 文件材料名字
        file_suffix: "", // 文件后缀名字
        path: "" // 文件地址
      },
      rules: {
        file_type_id: [
          { required: true, message: "请选择文件属性类型", trigger: "change" }
        ],
        path: [
          { required: true, message: "请选择文件上传", trigger: "change" }
        ],
        pids: [
          { required: true, message: "请选择文件存放位置", trigger: "change" }
        ],
        pid: [{ required: true, message: "请选择文件夹", trigger: "change" }]
      },
      ruleForm: {},

      dialogTitle: "添加文件", //上传文件标题
      display: false
    };
  },
  created() {
    // let token = sessionStorage.superToken; // 超管token
    // this.uploadHear.Authorization = "Bearer " + token;
    this.getOrganizationList("", 1);
  },
  methods: {
    //分页切换
    currentPageChange(p) {
      this.getOrganizationList(this.inputValue, p);
    },
    //编辑
    editClick(row) {
      this.$router.push(`/organization/edit/${row.id}`); //("/organization/edit/:id");
    },

    // 控制上传弹窗
    uploadDisplayChange(msg) {
      this.uploadDisplay = msg;
    },
    cancel() {
      this.display = false;
      this.getOrganizationList();
    },
    //添加文件
    addFiles() {
      this.display = true;
    },
    // 上传文件超出个数限制
    onExceed() {
      error("上传文件超出个数");
    },
    //上传文件前
    beforeAvatarUpload(file) {
      let fileArr = file.name.split(".");
      let arr = ["xlsx", "xls"];
      if (arr.indexOf(fileArr[fileArr.length - 1]) === -1) {
        error("请确认文件上传格式");
        return false;
      }
    },
    // 移除文件，只是本地删除
    handleRemove() {
      this.form.file_name = "";
    },
    beforeClose() {},

    //审核状态选择change事件
    stateChange(stateId, row) {
      this.$superHttp
        .post(this.URL.superUrl.checkSubmit, {
          id: row.id,
          state: stateId
        })
        .then(res => {
          if (res.data.code === 200) {
            success("状态修改成功");
          }
          // else if (res.data.code === 400) {
          //   error(res.data.message);
          // }
        });
    },

    //删除列表
    delClick(row) {
      this.$confirm(" 是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$superHttp
            .delete(this.URL.superUrl.organs + "/" + row.id, {
              params: {
                id: row.id
              }
            })
            .then(res => {
              this.getOrganizationList(this.inputValue);
              console.log(res);
            });
          success("删除成功");
        })
        .catch(() => {});
    },

    //获取机构管理列表接口
    getOrganizationList(name, page) {
      this.$superHttp
        .get(this.URL.superUrl.organs, {
          params: {
            name,
            page
          }
        })
        .then(res => {
          this.organizationData = res.data.data.organs;
          this.pageData = res.data.data.meta;
          this.load = false;
        });
    },
    searchOrgan() {
      this.load = true;
      this.getOrganizationList(this.inputValue, "");
    }
  }

  // computed: {
  //   // 模糊搜索
  //   tables() {
  //     const inputValue = this.inputValue;
  //     if (inputValue) {
  //       return this.organizationData.filter(data => {
  //         return Object.keys(data).some(key => {
  //           return (
  //             String(data[key])
  //               .toLowerCase()
  //               .indexOf(inputValue) > -1
  //           );
  //         });
  //       });
  //     }
  //     return this.organizationData;
  //   }
  // }
};
</script>
<style lang="less" scoped >
#organization {
  .search-input {
    margin-bottom: 20px;
  }
}
</style>
